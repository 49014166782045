import { CardContent } from "@mui/material";
import { Button, Title } from "react-admin";
import { DateRangePicker, Stack } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import { useState } from "react";
import { DownloadButton } from "./analytics";
import { DateTime } from "luxon";

export const ArtistPayments = () => {
    const [range, setRange] = useState<DateRange | null>(null);
    let artistDownloadUrl = undefined;
    let artistDownloadFilename = `artist_payment.csv`;

    if (range !== null) {
        let startDate = DateTime.fromJSDate(range[0]).toFormat("yyyy-MM-dd");
        let endDate = DateTime.fromJSDate(range[1]).toFormat("yyyy-MM-dd");
        artistDownloadUrl =
            import.meta.env.VITE_SERVER_URL + `/analytics/payment_csv?start_date=${startDate}&end_date=${endDate}`;
        artistDownloadFilename = `payment_${startDate}_${endDate}.zip`;
    }

    let trackStatUrl = import.meta.env.VITE_SERVER_URL + "/analytics/track_stats";
    let trackStatFilename = `track_stats_${DateTime.now().toISODate()}.csv`;

    const handleDateRangeChange = (value: DateRange | null, event: any) => {
        setRange(value);
    };

    return (
        <div>
            <Title title="Analytics" />
            <CardContent>
                <h1>Artist payment</h1>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginBottom: "20px",
                        alignItems: "start",
                        marginTop: "20px",
                    }}
                >
                    <DateRangePicker onChange={handleDateRangeChange} format="yyyy-MM-dd" placeholder="Select Date Range" />

                    <DownloadButton url={artistDownloadUrl} filename={artistDownloadFilename} />
                </div>
                <h1>Track stats</h1>
                <DownloadButton url={trackStatUrl} filename={trackStatFilename} />
            </CardContent>
        </div>
    );
};
