import {
    Create,
    EditButton,
    ImageField,
    List,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    DateField,
    DateInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    SelectInput,
    DateTimeInput,
    fetchRelatedRecords,
    FormDataConsumer,
    AutocompleteInput,
    useRecordContext,
    AutocompleteArrayInput,
    DatagridConfigurable,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    WrapperField,
    ArrayInput,
    SimpleFormIterator,
} from "react-admin";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import {
    DurationField,
    AudioPlayerField,
    MediaUploadField,
    SaveAndAddAnotherToolbar,
    LongDateField,
    ShortDateField,
} from "./customFields";
import { NamingCallout } from "./callouts";
import { dataProvider } from "./dataProvider";
import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { Analytics } from "./analytics";
import { captureError } from "./sentry";
import { TrackBulkActionButtons } from "./common";
import { ArtistReferenceInput, ManyArtistReferenceInput } from "./artists";
import { LabelReferenceInput } from "./labels";
import Tooltip from "@mui/material/Tooltip";
import { DEFAULT_IS_PUBLIC } from "./config";
import { TagCreatePopup } from "./tags";
import { OrderedSingleFieldList } from "./orderedSingleFieldList";

const lockedTypes = [
    { id: "timed", name: "Until release date" },
    { id: "forever", name: "Forever" },
];

const lockedTypesWithFree = [{ id: "free", name: "Free" }, ...lockedTypes];

const trackFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="is_public" label="Public" defaultValue={true} />,
    <ArtistReferenceInput source="artist_id" label="Artist" />,
    <LabelReferenceInput source="label_id" label="Label" />,
    <ReferenceArrayInput source="genre_ids" label="Genre" reference="genres" />,
    <ReferenceArrayInput source="tag_ids" label="Tag" reference="tags" />,
    <BooleanInput source="is_ddex_available" label="DDEX available" defaultValue={true} />,
    <BooleanInput source="is_ddex_upload" label="DDEX upload" defaultValue={true} />,
    <BooleanInput source="has_public_genre" label="Has public genre" defaultValue={true} />,
    <SelectInput
        choices={lockedTypesWithFree}
        validate={[required()]}
        source="lock_type"
        label="Lock type"
        defaultValue="free"
    />,
];

const supportedPrices = [
    { id: "0", name: "Free" },
    { id: "0.99", name: "$0.99" },
    { id: "1.29", name: "$1.29" },
    { id: "1.49", name: "$1.49" },
    { id: "1.99", name: "$1.99" },
    { id: "2.99", name: "$2.99" },
    { id: "4.99", name: "$4.99" },
    { id: "7.99", name: "$7.99" },
    { id: "9.99", name: "$9.99" },
    { id: "14.99", name: "$14.99" },
    { id: "19.99", name: "$19.99" },
];
const defaultPrice = "0";

const exporter = async (tracks: any) => {
    let artists = await fetchRelatedRecords(dataProvider)(tracks, "artist_ids", "artists");
    let genres = await fetchRelatedRecords(dataProvider)(tracks, "genre_ids", "genres");
    let labels = await fetchRelatedRecords(dataProvider)(tracks, "label_id", "labels");

    const data = tracks.map((track: any) => {
        let artist_names = track.artist_ids.map((artist_id: string) => artists[artist_id].name);

        return {
            id: track.id,
            name: track.name,
            artist_names: artist_names.join(", "),
            label_name: track.label_id ? labels[track.label_id].name : null,
            genres: track.genre_ids.map((genre_id: number) => genres[genre_id].name),
            is_public: track.is_public,
            audio_url: track.audio_url,
            image_url: track.image_url,
            release_date: track.release_date,
        };
    });

    jsonExport(
        data,
        {
            headers: [
                "id",
                "name",
                "artist_names",
                "label_name",
                "genres",
                "is_public",
                "audio_url",
                "image_url",
                "release_date",
            ],
        },
        (_: any, csv: any) => {
            downloadCSV(csv, "tracks");
        }
    );
};

const TrackActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const TrackList = () => (
    <List
        exporter={exporter}
        filters={trackFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        actions={<TrackActions />}
    >
        <DatagridConfigurable
            rowClick="show"
            bulkActionButtons={<TrackBulkActionButtons />}
            omit={["genres", "is_ddex_available", "label_id", "release_date"]}
        >
            <TextField source="name" />
            <ImageField source="thumbnail_url" title="Track cover" label="Image" sortable={false} />
            <ReferenceArrayField label="Artists" reference="artists" source="artist_ids">
                <OrderedSingleFieldList source="artist_ids">
                    <ChipField source="name" size="small" />
                </OrderedSingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" sortable={false} />
            <ReferenceArrayField label="Tags" reference="tags" source="tag_ids" sortable={false} />
            <ReferenceField source="label_id" reference="labels" label="Label" link="show" />
            <BooleanField source="is_public" label="Public" />
            <BooleanField source="is_ddex_available" label="DDEX Available" />
            <ShortDateField source="created_at" label="Added on" />
            <ShortDateField source="release_date" label="Release date" />
            <WrapperField label="Edit">
                <EditButton />
            </WrapperField>
        </DatagridConfigurable>
    </List>
);

export const TrackShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Track Details</h2>
            <TextField source="name" label="Track Name" />
            <ArrayField source="status" label="Processing Status">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" size="small" />
                </SingleFieldList>
            </ArrayField>
            <ReferenceArrayField label="Artists" reference="artists" source="artist_ids">
                <OrderedSingleFieldList source="artist_ids">
                    <ChipField source="name" size="small" />
                </OrderedSingleFieldList>
            </ReferenceArrayField>
            <ImageField source="image_url" title="Track cover" label="Artwork" />
            <ReferenceField source="label_id" reference="labels" label="Label" link="show" />
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
            <TextField source="stream_count" label="Stream count" />
            <BooleanField source="is_public" />
            <BooleanField source="is_ddex_available" />
            <LongDateField source="created_at" label="Added on" />
            <LongDateField source="unlock_date" label="Unlocked on" />
            <TextField source="unlock_price_usd" label="Unlock price usd" />
            <AudioPlayerField />
            <DateField source="release_date" label="Release date" />

            <DurationField source="duration_ms" label="Duration" />
            <ReferenceArrayField source="album_ids" reference="albums" label="Albums" />
            <ReferenceArrayField source="featured_artist_ids" reference="artists" label="Featured artists" />
            <ReferenceArrayField label="Tags" reference="tags" source="tag_ids" />
            <TextField source="id" />
            <Analytics resource="tracks" />
        </SimpleShowLayout>
    </Show>
);

const validateTrack = (values: any) => {
    try {
        const errors: any = {};
        console.log(values);
        if (values.genre_ids && values.genre_ids.length === 0) {
            errors.genre_ids = "Missing genres";
        }
        if (values.name && values.name.length > 100) {
            errors.name = `Name too big (${values.name.length}): 100 chars maximum`;
        }
        if (values.unlock_price_usd !== "0" && values.lock_type == null) {
            errors.lock_type = `Missing lock type`;
        }
        if (
            !values.artist_ids ||
            values.artist_ids.length == 0 ||
            !values.artist_ids.every((element: string) => element !== "")
        ) {
            errors.artist_ids = "Missing artists";
        }
        return errors;
    } catch (error) {
        captureError(error);
        throw error;
    }
};

export const TrackEdit = () => {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm validate={validateTrack}>
                <h2>Edit Track</h2>

                <TrackDetailsEditor is_edit={true} />

                <h2>Media Upload</h2>

                <MediaUploadField mediaType="image" requiresValidation={false} />
                <MediaUploadField mediaType="audio" requiresValidation={true} />
            </SimpleForm>
        </Edit>
    );
};

export const TrackCreate = () => (
    <Create>
        <SimpleForm validate={validateTrack} toolbar={<SaveAndAddAnotherToolbar />}>
            <h2>Add Track</h2>

            <TrackDetailsEditor is_edit={false} />

            <h2>Media Upload</h2>

            <MediaUploadField mediaType="image" requiresValidation={true} />
            <MediaUploadField mediaType="audio" requiresValidation={true} />
        </SimpleForm>
    </Create>
);

type Track = {
    is_public: boolean;
    lock_type: string | null;
    unlock_date: string | null;
    unlock_price_usd: string | null;
};

const TrackUnlockSelector = (prop: { is_edit: boolean; formData: Track }) => {
    if (prop.formData.unlock_price_usd !== "0") {
        return (
            <>
                <SelectInput source="lock_type" choices={lockedTypes} validate={[required()]} label="Lock type" />
                {prop.formData.lock_type === "timed" && (
                    <DateTimeInput source="unlock_date" label="Release date" validate={[required()]} />
                )}
            </>
        );
    } else {
        return <></>;
    }
};

export const TrackDetailsEditor = (prop: { is_edit: boolean }) => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 2, maxWidth: "500px" }}>
                    <TextInput autoComplete="off" validate={[required()]} fullWidth source="name" label="Track Name" />
                    <ArrayInput source="artist_ids" fullWidth label="Artists">
                        <SimpleFormIterator inline>
                            <ArtistReferenceInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <FormDataConsumer<Track>>
                        {({ formData }) => (
                            <div style={{ display: "flex", gap: "10px" }}>
                                <SelectInput
                                    source="unlock_price_usd"
                                    choices={supportedPrices}
                                    defaultValue={defaultPrice}
                                    validate={[required()]}
                                    label="Price"
                                />
                                <TrackUnlockSelector formData={formData} is_edit={prop.is_edit} />
                            </div>
                        )}
                    </FormDataConsumer>
                    <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
                </div>

                <div style={{ flex: 1, marginLeft: "20px" }}>
                    <NamingCallout />
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
                <div style={{ flex: 2, maxWidth: "500px" }}>
                    <ReferenceArrayInput label="Genres" source="genre_ids" reference="genres" />
                    <ManyArtistReferenceInput label="Featured artists" source="featured_artist_ids" />

                    <LabelReferenceInput />

                    <Tooltip title="Release date shown in the app, only informational">
                        <DateInput source="release_date" label="Release date" />
                    </Tooltip>
                    <ReferenceArrayInput source="tag_ids" reference="tags">
                        <AutocompleteArrayInput label="Tags" create={<TagCreatePopup />} />
                    </ReferenceArrayInput>
                </div>
            </div>
        </div>
    );
};

const TrackOptionRenderer = () => {
    const record = useRecordContext();
    return (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src={record.thumbnail_url} style={{ width: "40px" }} />
            {record.name}
        </div>
    );
};
const trackOptionText = <TrackOptionRenderer />;
const trackInputText = (choice: any) => `${choice.name}`;

export const TrackReferenceInput = (props: { label?: string; source?: string }) => {
    const record = useRecordContext();
    const recordExists = !!record;
    const { label = "Track", source = "track_id" } = props;

    return (
        <ReferenceInput fullWidth source={source} reference="tracks" label={label} sort={{ field: "_search", order: "DESC" }}>
            <AutocompleteInput
                inputText={trackInputText}
                optionText={trackOptionText}
                defaultValue={recordExists ? undefined : null}
                style={{ width: "500px" }}
                fullWidth
            />
        </ReferenceInput>
    );
};
